<template>
    <div>
        <div class="topCenter" v-if="enterpriseinfo">
            <div :style="{width: this.$oucy.pageWidth+'px'}" class="topCenterBody">
                <img :src="$oucy.ossUrl+ enterpriseinfo.enterpriseAvata" class="merchantLogo" v-if="enterpriseinfo && enterpriseinfo.enterpriseAvata">
                <div class="merchantText p-l-25">
                    <div class="df-jc-s-b df-ai-c">
                        <div class="df-ai-c">
                            <div class="df">
                                <div class="f24 pointer" @click="$oucy.go('/Shop?enterpriseId='+enterpriseinfo.id, null)">{{ enterpriseinfo.enterpriseShopName}}</div>
                                <enterprise-grade :enterprise="enterpriseinfo" />
                            </div>
                            <enterpriseClaim :enterpriseId="enterpriseinfo.id" class="m-l-10" />
                        </div>
                        <div class="df-ai-c f14 c-6">
                            <search-product2 @ourShop="ourShop"></search-product2>
                            <!-- <span class="df-ai-c pointer m-r-30"><img class="m-r-5" src="@/assets/icon/share_icon.png">分享</span>
                            <span class="df-ai-c pointer">
                                <img class="m-r-5" src="@/assets/icon/attention_icon.png">{{enterpriseinfo.isFocus?'已':''}}关注</span> -->
                        </div>
                    </div>
                    <div class="f14 m-t-20 c-6">
                        <span class="">
                            星级
                        </span>
                        <el-rate class="m-r-30 " v-model="enterpriseinfo.enterpriseStar/2" disabled disabled-void-color="#888888" void-color="#888888" score-template="{value}" style="display: inline-block;position: relative;top:-2px">
                        </el-rate>
                        <!-- <img src="@/assets/icon/star_icon.png">
              <img src="@/assets/icon/star_icon.png">
              <img src="@/assets/icon/star_icon.png">
              <img src="@/assets/icon/star_icon.png">
              <img src="@/assets/icon/star_icon_not.png"> -->
                        <span class="m-r-30">品质{{enterpriseinfo.enterpriseScore}}</span>
                        <span>粉丝{{enterpriseinfo.enterpriseFansCount}}</span>
                    </div>
                    <div class="m-t-20">
                        <el-button-group>
                            <el-button type="" :icon="isCollect?'el-icon-star-on':'el-icon-star-off'" size="mini" @click="saveCollectUser(enterpriseinfo)">收藏</el-button>
                            <el-button @click="decoderScene" type="" icon="el-icon-s-promotion" size="mini">分享</el-button>
                            <el-button type="" size="mini" @click="openMessage">在线客服</el-button>
                            <!-- <el-button type="" size="mini">店铺海报</el-button> -->
                            <el-button type="" size="mini">意见反馈</el-button>
                        </el-button-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="topTabsBody">
            <div :style="{width: this.$oucy.pageWidth+'px'}" class="df-jc-s-b topTabs">
              <div class="topTab df-ai-c" :class="select==4?'select':''" @click="$oucy.go('/Shop?enterpriseId='+enterpriseinfo.id)"><img src="@/assets/images/product/shophome.png" style="width:32px;height: auto">店铺首页</div>
                <div class="topTab df-ai-c">
                    <!-- <img src="@/assets/images/product/Certification_1.png">认证档案 -->
                    <el-popover placement="bottom" width="700" trigger="hover">
                        <div class="Certifications" v-if="enterpriseinfo.enterpriseCertificationFile">
                            <div class="Certification-top">
                                <div class="box">
                                    <div class="box-top">{{enterpriseinfo.enterpriseCertificationFile.fileCountEmploeey}}</div>
                                    <div class="box-bottom">员工人数</div>
                                </div>
                                <div class="box">
                                    <div class="box-top">{{enterpriseinfo.enterpriseCertificationFile.fileCountPatent}}</div>
                                    <div class="box-bottom">专利数量</div>
                                </div>
                                <div class="box">
                                    <div class="box-top">{{enterpriseinfo.enterpriseCertificationFile.fileYield}}</div>
                                    <div class="box-bottom">年产量</div>
                                </div>
                                <div class="box">
                                    <div class="box-top">{{enterpriseinfo.enterpriseCertificationFile.fileProductPeriod}}</div>
                                    <div class="box-bottom">生产周期</div>
                                </div>
                                <div class="box">
                                    <div class="box-top">{{enterpriseinfo.enterpriseCertificationFile.fileShowArea}}</div>
                                    <div class="box-bottom">展厅面积</div>
                                </div>
                                <div class="box">
                                    <div class="box-top">{{enterpriseinfo.enterpriseCertificationFile.fileFactoryArea}}</div>
                                    <div class="box-bottom">工厂面积</div>
                                </div>
                            </div>
                            <div class="m-t-15">
                                <span class="tabTitle">生产类型</span><span class="tag" v-for="(v,i) of enterpriseinfo.enterpriseCertificationFile.fileProductTypes" :key="i">{{v}}</span>
                            </div>
                            <div class="m-t-10">
                                <span class="tabTitle">主营范围</span><span class="tag" v-for="(v,i) of enterpriseinfo.enterpriseCertificationFile.fileMainScope" :key="i">{{v}}</span>
                            </div>
                            <div class="m-t-10">
                                <span class="tabTitle">发 货 地</span><span class="m-l-10 f12 c-1">{{enterpriseinfo.enterpriseCertificationFile.fileDispatch}}</span>
                            </div>
                        </div>
                        <a slot="reference" style="width: 256px;" class="df-ai-c df-jc-c" :class="select==0?'select':''"><img src="@/assets/images/product/Certification_1.png"><span style="">认证档案</span></a>
                    </el-popover>
                </div>
                <div class="topTab df-ai-c" :class="select==1?'select':''" @click="gotoCard"><img src="@/assets/images/product/photo_1.png">企业名片</div>
<!--              <div class="topTab df-ai-c" :class="select==1?'select':''" title="暂未开放"><img src="@/assets/images/product/photo_1.png">企业相册</div>-->
                <div class="topTab df-ai-c" :class="select==2?'select':''" @click="$oucy.go('/ShopVideo?enterpriseId='+enterpriseinfo.id)"><img src="@/assets/images/product/video_1.png">企业视频</div>
                <!-- <div class="topTab df-ai-c"><img src="@/assets/images/product/VR.png">全景VR</div> -->
<!--                <div class="topTab df-ai-c" :class="select==3?'select':''" title="暂未开放"><img src="@/assets/images/product/plant_1.png">联系工厂</div>-->
            </div>
        </div>
        <div class="image__preview" v-if="show" @click="show=false">
          <el-image 
            style="height: 80%;width: auto;"
            :src="url" 
            :preview-src-list="[url]" @click.stop="download()">
          </el-image>
        </div>
        <!-- 分享海报 -->
        <sharePoster ref="sharePoster"></sharePoster>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { localSet, localGet, localDel } from "@/store/store";
import {scence, poster, collectuser, cardEnterprise} from "@/service"
export default {
    name: "top",
    props: {
        enterpriseinfo: {
            type: Object,
            default: function() {
                return {}
            }
        },
        select: {
            type: [String, Number],
            default: null
        },
    },
    data() {
        return {
            pageWidth: oucy.pageWidth,
            bannerHeight: 1000,
            bannerImgHeight: 560,
            bannerImgWidth: 1920,
            screenWidth: 0,
            setSizeCount: 0,
            setSizeTimId: null,
            url:null,
            show:null,
            isCollect: null,
          enterpriseId:null,
        }

    },
    mounted() {
      this.enterpriseId = this.$route.query.enterpriseId
        let loginUser=localGet(this.$oucy.userInfoKey)
        if(loginUser && loginUser.id){
                this.getIsCollect()
        }else{
            // 可能是在这个页面登陆的
            setTimeout(()=>{
                let loginUser=localGet(this.$oucy.userInfoKey)
                if(loginUser && loginUser.id){
                    this.getIsCollect()
                }
            },5000)
        }
    },

    methods: {
        ourShop(v) {
            this.$emit('ourShop', v)
        },
         openMessage(v){
            console.log(66)
            this.$emit('openMessage',v)
        },
        decoderScene() {
            let obj = {
                objectId: this.enterpriseinfo.id,
                playbillCategory: 9,
                redirectUrlScene:{id:this.enterpriseinfo.id}
            };
            this.$refs.sharePoster.show(obj)
        },
        // 是否收藏
        getIsCollect(){
            if(!this.enterpriseinfo || !this.enterpriseinfo.id) return
            collectuser.isCollect({collectId:this.enterpriseinfo.id}).then(res=>{
                this.isCollect=res
            })
        } ,
        gotoCard(){
          cardEnterprise.getCardEnterpriseDetail({id:this.enterpriseId}).then(res=>{
            if(res){
              this.$oucy.go(`/enterpriseDetail?id=${this.enterpriseId}`)
            }else{
              this.$alert('您还没有创建企业名片，请先使用移动端创建企业名片')
            }
          })
        },
        /**
         * 收藏企业
         * @param  {[type]} v [description]
         * @return {[type]}   [description]
         */
        saveCollectUser(v){
            collectuser.saveCollectUser({collectType:1,collectId:v.id}).then(res=>{
                this.isCollect=!this.isCollect
                this.$message('操作成功')
            })
        },
        download(url=this.url){
        
               let a = document.createElement("a");
               // a.download = `${new Date().toLocaleString()}.xlsx`;
               a.setAttribute('href',url)
               a.click(); 
        
        },
    }
}
</script>
<style scoped lang="less">
    .image__preview{
        position:fixed;
        left:0;
        top:0;
        z-index: 999;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items:center;
        background:rgba(0, 0, 0, .7);
    }
.buy_button {
    margin-left: -10px;
    margin-top: 15px;
}

.buy_button .el-button {
    width: 150px;
    position: relative;
    z-index: 1000;
}

.middle_bottom_left {
    margin-left: 20px;
    width: 210px;
    float: left;

}

.middle_bottom_right img {
    margin-left: 50px;

}

.com_card {
    background-image: url("../../../assets/goods/background.png");
    width: 210px;
    height: 150px;
    /*margin-top: 65px;*/
}

.com_card p {
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    text-align: center;
    margin: 0 auto;
}

.com_card img {
    padding: 10px;
    margin-top: 10px;
}

.store_kind {
    border-spacing: 0;
    width: 210px;
    /*height: 280px;*/
    // margin-top: 20px;
}

.store_kind th,
td {
    height: 40px;
    border: 1px solid #EAEAEA;
    text-align: center;
    line-height: 40px;
}

.store_kind th {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: #F8F8F8;
    color: #666666;
    text-align: center;
    line-height: 40px;
}

.store_kind .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
}

.customer_service {
    border-spacing: 0;
    width: 210px;
    // height: 280px;
    margin-top: 20px;
}

.customer_service th {
    font-family: Microsoft YaHei;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    background: #F8F8F8;
    color: #666666;
    border: 1px solid #EAEAEA;
    text-align: center;
    line-height: 40px;
}

.customer_service td {
    height: 60px;
    border: 1px solid #EAEAEA;
    // margin-top: 30px;
    text-align: center;
    line-height: 60px;
}

.customer_service img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    float: left;
}

.customer_service .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
}

.sales_rank {
    border-spacing: 0;
    width: 210px;
    margin-top: 20px;
    /*height: 1302px;*/
    background: #FFFFFF;
    border: 1px solid #EFDFCA;
    border-collapse: collapse;
    padding-bottom: 20px;
}

.sales_rank th {
    width: 210px;
    height: 48px;
    background: linear-gradient(0deg, #FFFDF8, #FFF6EA);
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.sales_rank td {
    border: none;
}

.goods_name {
    width: 188px;
    height: 40px;
    background: #FAFAFA;
    margin: 0 auto;
    position: relative;
    top: -8px;
}

.goods_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
}

.top {
    width: 820px;
    height: 32px;
    background: #F8F8F8;
    margin-left: 80px;
    margin-top: 20px;
}

.top p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 11px;
    text-align: center;
    float: left;
    margin-left: 22px;
}


/deep/ .el-divider {
    width: 820px;
    margin-left: 80px;
}

.vhtml /deep/ img {
    max-width: 100%;
}

.right_button {
    float: right;
    margin-right: 30px;
}

.right_button .el-button {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.right_button .el-button:focus {
    font-weight: bold;
    color: #2894FF !important;
}

.comment {
    float: right;
    width: 820px;
    margin-right: 30px;
}

.comment_1 {
    background: #F8F8F8;
    border-radius: 4px;
}

.comment_2 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_3 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_4 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_right {
    width: 760px;
    height: 112px;
    padding: 10px;
    text-align: left
}

.comment_right_p1 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.comment_right_p2 {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #AAAAAA;
    text-align: left;
    position: relative;
}

.comment_right span {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 52px;
}

.comment_img {
    width: 100%;
    margin-left: -10px;
}

.comment_img img {
    margin-left: 10px;
    float: left;
}

.comment_img img .active {
    border: 4px #2090FF solid;
}

.comment_reply {
    background-image: url("../../../assets/background/reply_bk.png");
    background-repeat: no-repeat;
    padding: 30px;
    width: 665px;
}

.comment_reply span {
    float: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #5B6069;
    line-height: 20px;
    margin-left: -8px;
}

.comment_reply p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 4px;
}

.comment_reply img {
    margin-top: 0px;
    margin-left: 0px;
}

.username {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
}

.footer_footer {
    margin-top: 150px;
}

.footer_footer table {
    margin: 0px auto;
}

.footer_footer table td {
    border: none;
    text-align: left;
}

.footer_footer span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    padding: 10px;
}


.drawer_left_top {
    width: 220px;
    height: 64px;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_left {
    width: 220px;
    height: 935px;
    margin-top: -20px;
    background-color: #F4F4F4;
}

.product_name ul li:hover {
    background: #FFFFFF;
}

.product_name ul li {
    width: 180px;
    height: 96px;
    list-style: none;
    cursor: pointer;
    margin-left: -40px;
    padding: 20px 20px;
}

.product_name span {
    line-height: 30px;
}

.product_condition {
    text-align: left;
    margin-top: -15px;
}

.product_condition p {
    width: 207px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.product_condition .span1 {
    width: 132px;
    height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
}

.product_condition .span2 {
    width: 63px;
    height: 12px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ED6732;
}

/deep/ .el-input-number__inner {
    width: 20px;
}

.fangan_name {
    height: 18px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    float: left;
    padding: 20px;
}

/*头部*/
.topNav {
    background: #fff;
    padding: 10px 0;
}

.df-jc-s-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 58px;
    width: 163px;
}

.input-container {
    width: 500px;
    /*margin: 0 auto;*/
}

.topCenter {
    background: url('../../../assets/images/product/bG.png');
    background-size: 100%;
}

.topCenterBody {
    height: 216px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: left;

}

.merchantLogo {
    width: 128px;
    height: 128px;
}

.merchantText {
    flex: 1;
}

.topBottom {
    width: 100%;
    background: #fff;
}

.breadcrumbBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.z-skus {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.z-sku {
    width: 30%;
    padding-right: 10px;
    box-sizing: border-box;
    margin-right: 10px;
}

.lisColor {
    box-sizing: border-box;
    border: 3px solid #2090FF;
}

.notlisColor {
    box-sizing: border-box;
    border: 3px solid #fff;

}

/*头部*/

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.product.topBottom .el-menu.el-menu--horizontal {
    border: none;
    /*font-weight: bold;*/
    font-size: 16px;
    color: #333;
}

.product .el-menu-item {
    font-size: 16px;
}

.product .el-menu--horizontal>.el-menu-item {

    color: #333;
}

.product .el-menu--horizontal>.el-submenu .el-submenu__title {

    font-size: 16px;
    color: #333;
}

.size1 img.corner {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
}

.size1.select {
    border-color: #2090FF;
}

.size1.select img.corner {
    display: inline-block;
}

.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu-item {
    /*margin-left:20px;*/
    z-index: 1000;
}



.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}


.el-button--text:hover {
    /*color: rgb(32,144,255) !important;*/
}

.el-button--mini {
    border: none;
}

.el-button--mini:hover {
    /*background-color: rgb(32,144,255) !important;*/
}

/*.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}*/


.list .el-table__row>td {
    /*去除表格线*/
    border: none;
}

.list .el-table th.is-leaf {
    /*去除上边框*/
    border: none;
}

.list .el-table::before {
    /*去除下边框*/
    height: 0;
}

.wangge {
    float: right;
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}

.alignRight {
    // display: flex;
    flex-direction: row-reverse;
}

.middle ul li:nth-child(4n) {
    margin-right: 0px;
}

.middle ul {
    text-align: left;
}

.middle ul li {
    width: 170px;
    height: 340px;
    background-color: #FFFFFF;
    // padding: 27px;
    /*float: left;*/
    display: inline-block;
    list-style: none;
    cursor: pointer;
}

.title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #111111;
    line-height: 1.2;
}

.brand_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    // float: left;
    margin-right: 5px;
}

.price {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E92121;
    line-height: 50px;
}

.stock {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 50px;
}

.add .el-button--text {
    // box-sizing:border-box;
    width: 100%;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    margin-top: 15px;
    line-height: 0px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}

.liebiao {
    min-height: 500px;
}

.liebiao ul li {
    width: 850px;
    height: 100px;
    background-color: #FFFFFF;
    list-style: none;
    margin-left: -20px;
}

table {
    text-align: left;
    // margin-top: 15px;
}

.liebiao .el-button--text {
    width: 90px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    line-height: 0px;
}

.grid {
    /*min-height: 500px;*/
}

.grid ul {
    // margin-left: -25px;

}

.el-page-header__title {
    background-color: red;
}

.wangge {
    float: right;
    margin: 15px 30px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}

.brand_name {
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    /*float: left;*/
    height: 15px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}

/*/deep/ .btn-next{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .btn-prev{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .el-pager{position: relative;top: 9px;}
/deep/ .number{background: #FFFFFF!important;}
/deep/ .active{background:#2090ff!important ;}*/
.classifyIcon {
    width: 15px;
    height: 15px;
}

.spuImgs {
    width: 219px;
    height: 219px;
    background-color: #f1f1f1;
}

.spuImgs2 {
    width: 96px;
    height: 96px;
    background-color: #f1f1f1;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.el-menu-item,
.product /deep/ .el-submenu__title {
    font-size: 16px;
    color: #333;
}

.carousel img {
    /*设置图片宽度和浏览器宽度一致*/
    width: 100%;
    height: inherit;
}

.headline {
    text-align: left;
    margin-top: 10px;
    padding-top: 40px;
    font-size: 18px;
    font-weight: bold;
}

.centerNav {
    padding: 15px 0;
}

.nav {
    cursor: pointer;
    flex: 1;
    text-align: center;

    span {
        margin: 0 auto;
        width: 30px;
        height: 6px;
        background: #323A4A;
        border-radius: 2px;
        position: relative;
        display: none;
        top: 16px;
    }

    &.selsct {
        span {
            display: block;
        }

    }
}

.right_top {
    display: flex;
    background: #fff;
    padding: 20px;

    .inputprice {
        width: 76px;
    }
}

.store_kind .el-button.selsct {
    /*    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;*/
    color: #2090FF;
}

.line-h {
    display: inline-block;
    width: 5px;
    height: 16px;
    background: #2090FF;
    border-radius: 1px;
    position: relative;
    top: 2px;
}


// 主推产品
.recommendSpu,
.hotSpu {
    // width: 219px;
    display: flex;
}

.recommendSpu_item1 {
    width: 464px;
    height: 350px;
    margin-right: 30px;
}

.recommendSpu_item {
    margin: 0 13px;
}

.recommendSpu_item,
.hotSpu_item,
.newSpu_item {
    width: 219px;
    background: #fff;
    height: 350px;
}

.hotSpu {
    flex-wrap: wrap;
    // margin: 0 -13px;
}

.hotSpu_item,
.newSpu_item,
.navTypeSpu_item {
    width: 218px;
    margin-right: 26px;
    margin-top: 20px;
}

.newSpu {
    display: flex;
    flex-wrap: wrap;
}

.navTypeSpu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.navTypeSpu_item {}

.topTabsBody {
    background: #fff;
    color: #333;
}

.topTabs {
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    justify-content: center;
    height: 45px;
}

.topTab {
    text-align: center;
    justify-content: center;
    flex: 1;

    img {
        margin-right: 20px;
    }

    &:hover {
        background: #F8F8F8;
        color: #000;
    }

    &.select {
        background: #F8F8F8;
        border-bottom: 6px solid #2090FF;
    }
}

// 认证档案
.Certifications {

    .Certification-top {
        display: flex;

        .box {
            text-align: center;
            flex: 1;
            background: #F6F7F8;
            border-radius: 2px;
            margin: 5px;
            padding: 20px;

            .box-top {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2090FF;
            }

            .box-bottom {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #555C68;
                margin-top: 25px;
            }
        }
    }

    .tabTitle {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #AAAAAA;
    }

    .tag {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #555C68;
        background: #F6F7F8;
        border-radius: 1px;
        display: inline-block;
        padding: 2px 6px;
        margin-left: 10px;
    }
}

.shop {
    min-height: 750px;
}

.recommendSpu_item /deep/ .spuImgs,
.newSpu_item /deep/ .spuImgs,
.hotSpu /deep/ .spuImgs {
    width: 219px;
    height: 219px;
}

.navTypeSpu_item /deep/ .spuImgs {
    width: 218px;
    height: 218px;
}
</style>